import { ChakraProvider, extendTheme, theme } from '@chakra-ui/react'
import { useMemo } from 'react'
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { QueryParamProvider } from 'use-query-params'
import { Web3ReactProvider } from '@web3-react/core'


import { RefreshContextProvider } from './contexts/RefreshContext'
import ModalProvider from './contexts/ModalContext'

import { getLibrary } from './@helpers/web3-react.helper'
import MainLayoutCustom from './layouts/MainLayoutCustom'

import { HomeView } from './views/HomeView'


const breakpoints = {
  base: 0,
  '320': '20em',
  '375': '23.4375em',
  sm: '30em',
  md: '48em',
  lg: '62em',
  '2lg': '64em',
  '3lg': '68.75em',
  xl: '80em',
  xxl: '89em',
  '2xl': '96em',
  '3xl': '120em',
}

const customTheme = extendTheme({
  components: {
    Button: {
      baseStyle: {
        _focus: { boxShadow: 'none', background: 'transparent' },
        _active: { background: 'transparent', boxShadow: 'none' },
        _hover: { bg: 'transparent' },
      },
    },
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  colors: {
    primary: '#EF5DA8',
    secondary: '#00052E',
  },
  shadows: {
    ...theme.shadows,
    outline: 'none',
  },
  breakpoints,

})

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const RouteAdapter = ({ children }: { children: any }) => {
  const navigate = useNavigate()
  const location = useLocation()


  const adaptedHistory = useMemo(
    () => ({
      replace(location: any) {
        navigate(location, { replace: true, state: location.state })
      },
      push(location: any) {
        navigate(location, { replace: false, state: location.state })
      },
    }),
    [navigate],
  )
  return children({ history: adaptedHistory, location })
}

function App() {

  return (
    <ChakraProvider theme={customTheme}>
      <QueryParamProvider ReactRouterRoute={RouteAdapter as any}>
        <RefreshContextProvider>

          <ModalProvider>

            <MainLayoutCustom>
              <Routes>
                <Route caseSensitive path="/" element={<HomeView />} />
                <Route caseSensitive path=":refCode" element={<HomeView />} />
              </Routes>
            </MainLayoutCustom>

          </ModalProvider>
        </RefreshContextProvider>
      </QueryParamProvider>
    </ChakraProvider>
  )
}

const ContainerApp = () => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Web3ReactProvider>
  )
}


export default ContainerApp;