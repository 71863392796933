import { IEnvConfig } from '..';

const config: IEnvConfig = {
  name: "PRODUCTION",
  DOMAIN_URL: "https://qa-dapp.moverse.app",
  CONNECTORS: {
    ROOT: {
      baseUrl: "https://api-nft.famcentral.finance/"
    },
  },
  DEFAULT_CHAIN_CODE: "POLYGON",
  CHAINS: {
    POLYGON: {
      chainId: 137,
      chainName: "Polygon",
      nativeCurrency: {
        "name": "MATIC",
        "symbol": "MATIC",
        "decimals": 18
      },
      rpcUrls: [
        "https://polygon-rpc.com/",
        "https://rpc-mainnet.matic.network",
        "https://matic-mainnet.chainstacklabs.com",
        "https://rpc-mainnet.maticvigil.com",
        "https://rpc-mainnet.matic.quiknode.pro",
        "https://matic-mainnet-full-rpc.bwarelabs.com"
      ],
      blockExplorerUrls: ["https://polygonscan.com"],
    }
  },
  ADDRESS_CONFIG: {
    WBNB: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c".trim(),
    BUSD: "0xe9e7cea3dedca5984780bafc599bd69add087d56".trim(),
    DEX_FACTORY: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73".trim(), //pancakeswap
    DEX_ROUTER: "0x10ED43C718714eb63d5aA57B78B54704E256024E".trim(), // pancakeswap
    MOVE_TOKEN: "",
    SNEAKER_BOX: "",
    SNEAKER: "",
    POOL_STAKING_FIXED: "",
    POOL_STAKING_AUTO_APR: "",
    POOL_VESTING_PRIVATE: "",
    POOL_VESTING_PARTNER: "",
    POOL_VESTING_AIRDROP: "",
    MOVE: "".trim(),
    MOVERSE_BOX: "".trim(),
    MOVERSE_NFT: "".trim(),
    POO_AFF: "".trim(),
    POOL_LIQ_NFT: "".trim(),
  },
}

export default config;
