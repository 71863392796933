import { Box, Text, HStack, VStack, Image, SimpleGrid, Icon, Tooltip } from '@chakra-ui/react'
import { useParams, useSearchParams } from 'react-router-dom';
import useCopyToClipboard from '~/hooks/@common/useCopyToClipboard';
import { useWindowSize } from '~/hooks/@global';
import { ReactComponent as CopyClipboar } from '~/assets/svgs/copy.svg'

const androidLink = "https://drive.google.com/file/d/1gInzb2eLebOHGsHdX3tOngkgj93855qr/view";
const iosLink = "https://testflight.apple.com/join/fQaUZu7d";

const iosStore = "https://apps.apple.com/vn/app/moverse/id6445987297";

const androidStore = "https://play.google.com/store/apps/details?id=com.moverse.run.healthy";


export const HomeView = () => {
    const { width } = useWindowSize();
    const { refCode = "" } = useParams();
    const [value, copy] = useCopyToClipboard();


    const renderDownload = () => {
        return (
            <SimpleGrid
                w="full"
                columns={{ base: 1, lg: 2 }}
                spacing={4}
            >

                <HStack
                    border="1px solid #FF00FF"
                    py="15px"
                    borderRadius={"10px"}
                    _hover={{
                        backgroundColor: "transparent",
                        backgroundImage: "linear-gradient(180deg, #B700E5 0%, #8733F3 100%)",
                    }}
                    w="full"
                    justifyContent={"center"}
                    onClick={() => {
                        window.open(androidStore)
                    }}
                >
                    <Image
                        src={"https://moverse.biz/wp-content/uploads/2022/07/cib_app-store-ios.svg"}
                    />
                    <VStack spacing={0}>
                        <Text
                            color={"#fff"}
                            fontSize="12px"
                            lineHeight={"12px"}
                            fontWeight={700}
                            fontFamily="OutfitBold"
                        >
                            GOOGLE PLAY
                        </Text>
                        <Text
                            color={"#fff"}
                            fontSize="12px"
                            lineHeight={"12px"}
                            fontWeight={400}
                        >
                            {/* (APK Testing) */}
                        </Text>
                    </VStack>
                </HStack>


                <HStack
                    border="1px solid #FF00FF"
                    py="15px"
                    borderRadius={"10px"}
                    _hover={{
                        backgroundColor: "transparent",
                        backgroundImage: "linear-gradient(180deg, #B700E5 0%, #8733F3 100%)",
                    }}
                    w="full"
                    justifyContent={"center"}
                    onClick={() => {
                        window.open(iosStore)
                    }}
                >
                    <Image
                        src={"https://moverse.biz/wp-content/uploads/2022/07/Vector.svg"}
                    />
                    <VStack spacing={0}>
                        <Text
                            color={"#fff"}
                            fontSize="12px"
                            lineHeight={"12px"}
                            fontWeight={"bold"}
                            fontFamily="OutfitBold"
                        >
                            APP STORE
                        </Text>
                        <Text
                            color={"#fff"}
                            fontSize="12px"
                            lineHeight={"12px"}
                            fontWeight={400}
                        >
                            {/* (Testflight Testing) */}
                        </Text>
                    </VStack>
                </HStack>
            </SimpleGrid>
        )
    }

    const renderRef = () => {
        if (!refCode) {
            return null;
        }
        return (
            <HStack>
                <Text fontSize="20px" color={"#fff"} fontWeight="400" noOfLines={1}>Refferral: </Text>
                <Text fontSize="20px" color={"#fff"} fontWeight="bold" noOfLines={1}>{refCode}</Text>
                <Tooltip
                    label={value ? 'Copied' : 'Copy'}
                    placement='top-start'
                    color={"#fff"}
                >
                    <Icon
                        as={CopyClipboar}
                        cursor="pointer"
                        ml="15px"
                        onClick={() => {
                            copy(refCode);
                        }}
                    />
                </Tooltip>
            </HStack>
        )
    }

    if (width <= 500) {
        return (
            <Box
                w="full"
                px={{ base: "10px", md: "0px" }}
                py="40px"
            >

                <Box
                    backgroundImage={"https://moverse.app/wp-content/uploads/2022/08/building_mb.jpg"}
                    backgroundRepeat="no-repeat"
                    backgroundSize="cover"
                >
                    <Image
                        p="30px"
                        src={"https://moverse.biz/wp-content/uploads/2023/01/fitness-application.svg"}
                    />
                    <Image
                        pl="60px"
                        src={"https://moverse.biz/wp-content/uploads/2022/08/nguoi-1-min.png.webp"}
                    />
                </Box>


                <SimpleGrid
                    w="full"
                    columns={{ base: 1, md: 2 }}
                    spacing={6}
                    pt="20px"
                >

                    <VStack
                        w="full"
                        spacing={6}
                        alignItems={"center"}
                        order={{ base: 2, md: 1 }}
                    >

                        <Text
                            color={"#fff"}
                            fontSize="16px"
                            fontWeight={400}
                            maxWidth={{ base: "360px" }}
                            textAlign="center"
                        >
                            Moverse is a Fitness Social app that rewards people to interact, exercise and transcend a healthy lifestyle through advanced AI-powered solutions.
                        </Text>
                        {renderRef()}
                        {renderDownload()}

                    </VStack>

                </SimpleGrid>

            </Box>
        )
    }

    return (
        <Box
            w="full"
        >

            <Box h={{ base: "0px", md: "20vh", xl: "20vh", '2xl': "20vh" }} />

            <SimpleGrid
                w="full"
                columns={{ base: 1, md: 2 }}
                spacing={6}
            >

                <VStack
                    pt={{ md: "10px", xl: "20%" }}
                    px="10px"
                    spacing={6}
                    alignItems={"start"}
                    order={{ base: 2, md: 1 }}
                >
                    <Image
                        src={"https://moverse.biz/wp-content/uploads/2023/01/fitness-application.svg"}
                    />
                    <Text
                        color={"#fff"}
                        fontSize="20px"
                        fontWeight={400}
                        maxWidth={{ base: "563px" }}
                    >
                        Moverse is a Fitness Social app that rewards people to interact, exercise and transcend a healthy lifestyle through advanced AI-powered solutions.
                    </Text>

                    {renderRef()}
                    {renderDownload()}

                </VStack>

                <VStack
                    order={{ base: 1, md: 2 }}
                >
                    <Image
                        pl="50px"
                        src={"https://moverse.biz/wp-content/uploads/2022/08/nguoi-1-min.png.webp"}
                    />
                </VStack>

            </SimpleGrid>

            <Box h={{ base: "10px" }} />

        </Box>
    )
}
