import { useCallback, useState, } from 'react'
import {
  Box,
  Flex,
  HStack,
  Popover,
  Button,
  PopoverTrigger,
  Text,
  Image,
  VStack,
  Icon,
  PopoverContent,
  Center,
} from '@chakra-ui/react'

import NavLink from './NavLink'
import {
  ProSidebar,
  SidebarHeader,
  SidebarContent,
  Menu,
  MenuItem,
} from 'react-pro-sidebar'
import ConnectWalletButton from './ConnectWalletButton'
import LogoWeb from '~/assets/svgs/header/logo.png'
import { ReactComponent as MoverseTokenIcon } from '~/assets/svgs/icon-token/moverse.svg'
import { MainLinks } from '~/common/constants/HeaderRouter'
import { useBalanceToken, useConnectWallet, useWalletModal, useWindowSize } from '~/hooks/@global';
import { configEnv } from '~/@config'
import { useNavigate } from 'react-router-dom'
import { routers } from '~/common/constants/Router'
import { ReactComponent as CollapsedIcon } from '~/assets/svgs/collapse-menu-icon.svg'


const { MOVE_TOKEN } = configEnv().ADDRESS_CONFIG;

const Header = () => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Flex overflow="hidden" position="relative">

      <Center
        position="fixed"
        zIndex={99}
        w="100%"
      >
        <HStack
          maxW={{ base: "1200px" }}
          paddingY="20px"
          w="full"
        >
          <Image
            src={LogoWeb}
            w={collapsed ? "auto" : "200px"}
            height={collapsed ? "auto" : "51px"}
          />
          <Box />
        </HStack>
      </Center>

    </Flex>
  )
}

export default Header