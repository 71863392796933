import {
  Box,
  Center,
  Flex,
  Image,
} from '@chakra-ui/react'

import LogoWeb from '~/assets/svgs/header/logo.png'
import { useNavigate } from 'react-router-dom'

const HeaderMobile = () => {
  const history = useNavigate();

  return (
    <Box
      pt="10px"
      px={{
        base: '15px',
      }}
      h={{
        base: '65px',
      }}
      bg="rgba(0, 0, 0, 0.1)"
      position="fixed"
      zIndex={99}
      w="100%"
    >

      <Center>
        <Image
          src={LogoWeb}
          w="220px"
          h="50px"
          onClick={() => {
            history('/')
          }}
        />
      </Center>

    </Box>
  )
}

export default HeaderMobile
