import React from 'react'
import {
  Box,
  Center,
  Flex,
} from '@chakra-ui/react'
import Header from './Header'
import { useWindowSize } from '~/hooks/@global';

const MainLayoutCustom: React.FC = ({ children }) => {
  const { width } = useWindowSize();

  return (
    <Flex overflow="hidden" position="relative" h="100vh">
      <Header />
      <Box
        backgroundImage={
          width <= 500
            ? "none"
            :
            "https://moverse.biz/wp-content/uploads/2022/08/bg-1-1-min.jpg.webp"
        }
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        overflowY="auto"
        width="100%"
        position="relative"
        backgroundColor={"#280E4C"}
      >

        <Center
          w="100%"
        >
          <Box
            maxW={{ base: "auto", lg: "1200px" }}
          >
            {children}
          </Box>
        </Center>

      </Box>
    </Flex>
  )
}

export default MainLayoutCustom
